<template>
	<div class="home">
		<!-- <img class="yunxing" src="../assets/image/yunxing.jpg" alt /> -->
		<!-- <div class="yunxing" :style="{'visibility':bannerText?'':'hidden'}">{{ bannerText }}</div> -->
		<div class="home-concent">
			<div class="home-title">中思拓数据平台</div>
			<div class="navList">
				<div :class="['navItem', 'navItemicon' + (index + 1)]" @click="routePush(item)"  v-for="(item, index) in navList"
					:key="index">
					{{ item.name }}
				</div>
			</div>

			<div class="card-box">
				<div class="card" @click="routePush(item)" :style="{ width: item.width }" v-for="item in cardList"
					:key="item.name">
					<div class="top-title">
						<img :src="item.icon" alt="" srcset="">
						<div>{{ item.name }}</div>

					</div>
					<div class="card-text">
						{{ item.text }}
					</div>
				</div>

			</div>
			<!-- <div class="yunxing" :style="{'visibility':bannerText?'':'hidden'}">{{ bannerText }}</div> -->
			<div class="notice" :style="{ 'visibility': bannerText ? '' : 'hidden' }">
				<img width="34" height="28" src="../assets/image/home-notice.png" alt="">
				<div :style="{ 'visibility': bannerText ? '' : 'hidden' }">{{ bannerText }}</div>
			</div>
		</div>

		<!-- <div class="content">
			<div class="leftContent">
				<div class="topNav">
					<div class="navitem" :key="index" @click="routePush(item)" v-for="(item, index) in leftNavList">
						<img :class="[item.disabled?'disabled':'']" :src="item.icon" alt />
						<div class="itemName">{{ item.name }}</div>
					</div>
				</div>
				<div class="bottomContent">
					<div class="bottomTitle">
						<div>新闻公告</div>
					</div>
					<div class="itemContent">
						<div class="item" v-for="(item, index) in newsList" :key="index">
							<div class="info">
								<a style="color: #0b142a" :href="item.picUrl" target="_blank">{{
                  item.title
                }}</a>
							</div>
							<div class="time">{{ item.gmtCreate | formatDate }}</div>
							<div class="from" v-text="item.source">中国经营报</div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightContent">
				<div class="topNav">
					<div class="navitem" :key="index" @click="routePush(item)" v-for="(item, index) in rightNavList">
						<img :class="[item.disabled?'disabled':'']" :src="item.icon" alt />
						<div class="itemName">{{ item.name }}</div>
					</div>
				</div>
				<div class="bottomContent" style="overflow: hidden">

					<div class="tab" style="width: 100%">
						<Tabs v-model="tab" @on-click="onTab">
							<TabPane label="最新出让地块" name="tab1"></TabPane>
						</Tabs>
					</div>
					<div style="width: 780px; height: 300px" v-if="tab == 'tab1'">
						<Table border height="285px" :loading="tableLoading" tooltip-theme="light"
							@on-row-click="tableClick" :columns="columns" :data="data"></Table>
						<div style="text-align: right; margin-top: 5px">
							<Page :total="total" @on-change="tab1" />
						</div>
					</div>
					<div style="width: 780px" v-if="tab == 'tab2'">
						<Table border @on-row-click="gotoDetail" height="285px" tooltip-theme="light"
							:loading="tableLoading2" :columns="columns2" :data="data"></Table>
						<div style="text-align: right; margin-top: 5px">
							<Page :total="total" @on-change="tab2" />
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<Announcement />
	</div>
</template>

<script>
// @ is an alias to /src
import {
	newTjDk,
	newTjLp
} from "@/api/placeMarket";
import {
	queryNews,
	getBanner
} from "@/api/home";
import {
	get_model_tree
} from "@/api/router";
import {
	mapMutations,
	mapState
} from "vuex";
import {
	getObj
} from "@/api/user/user";

import {
	get_current_customer
} from "@/api/roleAndAccount";

import dataReports from "@/mixins/dataReports";
import Announcement from '../components/Announcement'

export default {
	name: "home",
	mixins: [dataReports],
	filters: {
		formatDate: function (val) {
			const value = new Date(val);
			const year = value.getFullYear();
			const month = value.getMonth() + 1;
			const day = value.getDate();
			const hour = value.getHours();
			const minutes = value.getMinutes();
			const seconds = value.getSeconds();
			return (
				year +
				"-" +
				(month >= 10 ? month : "0" + month) +
				"-" +
				(day >= 10 ? day : "0" + day)
			);
		},
	},
	data() {
		return {
			columns2: [{
				title: "序号",
				width: 80,
				ellipsis: true,
				tooltip: true,

				align: "center",
				type: "index",
			},
			{
				title: "城市",
				width: 80,
				ellipsis: true,
				tooltip: true,
				tooltipTheme: "light",
				key: "cs",
			},
			{
				title: "行政区",
				ellipsis: true,
				tooltip: true,

				key: "xzq",
			},
			// {
			//   title: '区域',
			//   key: 'sheng',
			//   render: (h, params) => {
			//     return h('span',
			//       params.row.sheng + params.row.xzq
			//     )
			//   }
			// },
			{
				title: "板块",
				key: "ssbk",
				ellipsis: true,
				tooltip: true,

				align: "left",
			},
			{
				title: "楼盘名称",
				key: "lpmc",
				ellipsis: true,

				align: "left",
				tooltip: true,
			},
			{
				title: "开发商",
				width: 140,
				ellipsis: true,

				tooltip: true,
				key: "kfs",
			},
			{
				title: "价格",
				key: "ysckj",
				width: 150,
				ellipsis: true,
				align: "left",
				tooltip: true,
			},
				// {
				//   title: '开盘时间',
				//   ellipsis: true,
				//   "tooltip": true,
				//   key: 'kpsj'
				// }
			],
			columns: [{
				title: "序号",
				ellipsis: true,

				width: 70,
				tooltip: true,
				align: "center",
				type: "index",
			},
			{
				title: "城市",
				ellipsis: true,

				tooltip: true,
				width: 100,
				key: "shi",
			},
			{
				title: "地块名称",
				key: "zdbhCr",
				ellipsis: true,

				align: "left",
				width: 100,
				tooltip: true,
			},
			{
				title: "用地性质",
				ellipsis: true,

				width: 100,
				tooltip: true,
				key: "tdyt",
			},
			{
				title: "用地面积",
				ellipsis: true,

				width: 100,
				tooltip: true,
				key: "zydmj",
			},
			{
				title: "容积率",
				ellipsis: true,

				width: 100,
				tooltip: true,
				key: "rjlms",
			},
			{
				title: "起始价",
				ellipsis: true,
				tooltip: true,
				width: 150,
				key: "qsjms",
				align: "center",
				render: (h, params) => {
					return h(
						"span",
						params.row.qsjms == "" ?
							"--" :
							params.row.qsjms)

				}
			},
			{
				title: "发布时间",
				ellipsis: true,
				tooltip: true,
				width: 100,
				key: "fbsjCr",
			},
			],
			data: [],
			cardList: [
				{
					name: "房屋智能估价",
					icon: require("../assets/image/home-fwzngj.png"),
					path: "/Empowerment/pledge/houseProperty/rapidValuation",
					width: '295px',
					text: '基于大数据算法和人工智能，对住房快速精准估价，又快有准，为银行等用户提供领先的数智风险管理工具。',
					disabled: false
				},
				{
					name: "数据服务",
					icon: require("../assets/image/数据服务.png"),
					path: "/dataIframe",
					modalName: "城市模块",
					width: '300px',
					text: '覆盖全国300多城，200城一手房供销存数据精准统计，广东省内T+2日更新。六大核心数据库，满足多元化、深层次定制需求。',
					disabled: false
				},
				{
					name: "市场监测与预警报告",
					icon: require("../assets/image//市场监测.png"),
					path: "/WarnResearch",
					width: '360px',
					text: '依托“房屋智能估价系统”与全链条数据集，监测市场变化，构建住房价格指数，精准预警楼盘价格“超跌”风险。',
					disabled: false
				},
			],
			leftNavList: [{
				name: "宏观经济",
				icon: require("../assets/image/icon1.png"),
				path: "/Insight/MacroEconomy",
				modalName: "宏观模块",
				disabled: false
			},
			{
				name: "土地市场",
				icon: require("../assets/image/icon2.png"),
				path: "/Insight/land/107/iframe",
				// path: "/Insight/PlaceMarket",
				modalName: "土地模块",
				disabled: false
			},
			{
				name: "金融市场",
				icon: require("../assets/image/icon3.png"),
				path: "/Insight/financialMarket",
				modalName: "金融模块",
				disabled: false
			},
			{
				name: "城市系统",
				icon: require("../assets/image/icon4.png"),
				path: "/Insight/City",
				modalName: "城市模块",
				disabled: false
			},
			{
				name: "企业监测",
				icon: require("../assets/image/icon5.png"),
				path: "/Insight/enterprise",
				modalName: "企业模块",
				disabled: false
			},
			{
				name: "舆情监测",
				icon: require("../assets/image/icon6.png"),
				disabled: true
			},
			],
			tab: "tab1",
			rightNavList: [{
				name: "一键看项目",
				icon: require("../assets/image/icon7.png"),
				path: "/Empowerment/Analysis/Home",
				disabled: false
			},
			{
				name: "房屋智能估价",
				icon: require("../assets/image/icon8.png"),
				path: "/Empowerment/pledge/houseProperty",
				disabled: false
			},
			{
				name: "企业研究",
				icon: require("../assets/image/icon9.png"),
				disabled: true
			},
			{
				name: "城市/区位研究",
				icon: require("../assets/image/icon10.png"),
				disabled: true
			},
			{
				name: "舆情预警",
				icon: require("../assets/image/icon11.png"),
				disabled: true
			},
			],
			searchValue: "",

			searchTipsList: [],
			searchLoading: false,
			tableLoading2: false,
			tableLoading: false,
			activeNav: 0,
			current: 1,
			total: 0,
			navList: [{
				name: "城市系统",
				id: 0,
				path: "/Insight/City/stat/cityStat",
				icon: require("../assets/image/homeicon1.png"),
				icon1: require("../assets/image/homeicon11.png"),
				// modalName: "宏观模块",
			},
			{
				name: "定制",
				id: 3,
					path: "/customerorder/customerorder",
				icon: require("../assets/image/homeicon2.png"),
				icon1: require("../assets/image/homeicon22.png"),

				// modalName: "企业模块",
			},
			{
				name: "数据确权可视化",
				id: 1,
				path: "https://address.zhongsituo.com:10444/shareScreen/eyJzY3JlZW5JZCI6OX0=",
				icon: require("../assets/image/homeicon3.png"),
				icon1: require("../assets/image/homeicon33.png"),
alink: true,
				// modalName: "土地模块",
			},

				// {
				// 	name: "舆情",
				// 	id: 4,
				// },
			],
			tip: [
				"请输入搜索的城市或指标分类",
				"请输入地块名称或地址",
				"请输入楼盘名称",
				"请输入企业名称或代码",
				"请输入搜索内容",
			],
			newsList: [],
			bannerText: ''
		};
	},
	components: {
		...mapState(["userId", "user"]),
		Announcement
	},
	methods: {
		...mapMutations(["changeState"]),
		getUserInfo() {
			if (localStorage.getItem("userId")) {
				getObj({
					id: localStorage.getItem("userId"),
				}).then((res) => {
					this.changeState({
						prop: "user",
						value: res,
					});
				});
			} else {
				this.$router.push({
					path: "/login",
				});
			}
		},
		async getCustomer() {
			let data = await get_current_customer()
			localStorage.setItem("customerInfo", JSON.stringify(data));
		},
		_load_news() {
			queryNews({
				current: 0,
				size: 9,
				contType: 4
			}).then((res) => {
				this.newsList = res.records;
			});
		},
		getBanner() {
			getBanner({
				origin: 1
			}).then((res) => {
				this.bannerText = res.text
			})
		},
		gotoDetail(item) {
			this.$router.push({
				path: `/Insight/City/search/buildingDetail`,
				query: {
					id: item.id,
					type: 0,
				},
			});
		},
		tableClick(info) {
			this.$router.push({
				path: `/Insight/PlaceDetail`,
				query: {
					id: info.id,
					type: "option",
					methods: 3,
				},
			});
		},
		tab1(page) {
			this.current = page;
			this.tabData1(1);
		},
		tab2(page) {
			this.current = page;
			this.tabData1(2);
		},
		tabData1(type) {
			if (type == 1) {
				this.tableLoading = true;
				newTjDk({
					size: 5,
					current: this.current,
					type: type,
				})
					.then((data) => {
						this.total = data.total;
						this.data = data.records;
					})
					.finally(() => {
						this.tableLoading = false;
					});
			} else if (type == 2) {
				this.tableLoading2 = true;
				newTjLp({
					size: 5,
					current: this.current,
					type: type,
				})
					.then((data) => {
						this.total = data.total;
						this.data = data.records;
					})
					.finally(() => {
						this.tableLoading2 = false;
					});
			}
		},
		onTab(obj) {
			this.current = 1;
			this.total = 0;
			if (obj == "tab1") {
				this.tabData1(1);
			} else {
				this.tabData1(2);
			}
		},
		searchHandle(item) {
			this.searchLoading = true;
			if (this.activeNav == 1) {
				if (item && item.key == "地块地址") {
					this.$router.push({
						path: `/Insight/land/listSearch`,
						query: {
							searchVal2: this.searchValue,
						},
					});
					return;
				}
				this.$router.push({
					path: `/Insight/land/listSearch`,
					query: {
						searchVal: this.searchValue,
					},
				});
			} else if (this.activeNav == 2) {
				this.$router.push({
					path: `/Insight/City/search/buildingSearch`,
					query: {
						searchVal: this.searchValue,
					},
				});
			} else if (this.activeNav == 0) {
				if (item && item.key == "城市") {
					this.$router.push({
						path: `/Insight/MacroEconomy/EconomicStatistics/DataSearch`,
						query: {
							city: this.searchValue,
						},
					});
				} else {
					this.$router.push({
						path: `/Insight/MacroEconomy/EconomicStatistics/DataSearch`,
						query: {
							searchVal: this.searchValue,
						},
					});
				}
			} else if (this.activeNav == 3) {
				this.$router.push({
					path: `/Insight/enterprise/enterpriseHome/enterpriseSearch`,
					query: {
						searchVal: this.searchValue,
					},
				});
			} else if (this.activeNav == 4) {
				this.$router.push({
					path: "/Insight/publicSentiment/hotspot",
					query: {
						searchVal: this.searchValue,
					},
				});
			}
			this.searchLoading = false;
		},
		changeNav(item) {


		},
		changeValue() {
			if (this.activeNav == 0) {
				this.searchValue &&
					(this.searchTipsList = [{
						key: "指标分类",
						item: [this.searchValue],
					},
					{
						key: "城市",
						item: [this.searchValue],
					},
					]);
				!this.searchValue && (this.searchTipsList = []);
			} else if (this.activeNav == 1) {
				this.searchValue &&
					(this.searchTipsList = [{
						key: "地块名称",
						item: [this.searchValue],
					},
					{
						key: "地块地址",
						item: [this.searchValue],
					},
					]);
				!this.searchValue && (this.searchTipsList = []);
			} else if (this.activeNav == 2) {
				this.searchValue &&
					(this.searchTipsList = [{
						key: "楼盘名称",
						item: [this.searchValue],
					},]);
				!this.searchValue && (this.searchTipsList = []);
			} else if (this.activeNav == 3) {
				this.searchValue &&
					(this.searchTipsList = [{
						key: "企业名称或代码",
						item: [this.searchValue],
					},]);
				!this.searchValue && (this.searchTipsList = []);
			} else if (this.activeNav == 4) {
				this.searchValue &&
					(this.searchTipsList = [{
						key: "关键字",
						item: [this.searchValue],
					},]);
				!this.searchValue && (this.searchTipsList = []);
			}
		},
		routePush(item) {
					if (item.alink) {
				window.open(item.path, "_blank");
				return
			}
			if (item.path) {
				if (item.modalName) {
					this.changeState({
						prop: "actionRouter",
						value: [],
					});
					get_model_tree({
						modelName: item.modalName,
					}).then((res) => {
						let actionRouter = [];
						res.children.map((item) => {
							let i = {
								url: location.protocol + "//" + location.host + item
									.fineReportUrl,
								title: item.indexName,
								children: [],
								icon: "ios-eye",
							};
							if (item.children) {
								item.children.map((item) => {
									i.children.push({
										url: location.protocol +
											"//" +
											location.host +
											item.fineReportUrl,
										title: item.indexName,
									});
								});
							}
							actionRouter.push(i);
						});
						this.changeState({
							prop: "actionRouter",
							value: actionRouter,
						});
						localStorage.setItem("actionRouter", JSON.stringify(actionRouter));
						if (res.children[0].fineReportUrl) {
							this.changeState({
								prop: "iframeUrl",
								value: location.protocol +
									"//" +
									location.host +
									res.children[0].fineReportUrl,
							});
							localStorage.setItem(
								"iframeUrl",
								location.protocol +
								"//" +
								location.host +
								res.children[0].fineReportUrl
							);
						} else {
							this.changeState({
								prop: "iframeUrl",
								value: "",
							});
							localStorage.setItem("iframeUrl", "");
						}
						this.$router.push(item.path);
					});
				} else {
					this.changeState({
						prop: "actionRouter",
						value: "",
					});
					localStorage.setItem("actionRouter", "");
					this.changeState({
						prop: "iframeUrl",
						value: "",
					});
					localStorage.setItem("iframeUrl", "");
					this.$router.push(item.path);
				}
			} else {
				this.$msg.error({
					type: "noAuthority",
					code: 1007,
				});
			}
		},
	},
	mounted() {

		this.tabData1(1);
		this._load_news();
		this.getUserInfo();
		this.getCustomer()
		this.getBanner()
		this.$nextTick(() => {
			// document.getElementsByClassName('searchBtn')[0].addEventListener('click', () => { this.$btnSysLog(1, 0, { pageId: 1, pageName: '首页' }) })
		})
	},
};
</script>


<style lang="scss" scoped>
@import "@/style/common.scss";

.home {
	width: 100%;
	height: 100%;
	padding-top: 28px;
	box-sizing: border-box;
	background: #f8fafb;
	overflow: auto;
	width: 100%;
	height: 100%;
	// background-size: 100%;
	background-image: url(../assets/image/home-bg.png);
	background-repeat: no-repeat;
	// overflow: hidden;
	background-size: cover;
	background-position-y: -58px;

	.home-concent {
		margin-left: 190px;

		.home-title {
			margin-top: 206px;
			margin-bottom: 56px;
			width: 336px;
			height: 67px;
			font-size: 48px;
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #0077FF;
			line-height: 0px;
		}
	}

	.yunxing {
		margin: 0 auto 50px;
		// display: block;
		display: inline-block;
		display: table;
		min-width: 900px;
		width: auto;
		padding: 0 10px;
		height: 60px;
		font-size: 32px;
		background-color: #FA0001;
		color: white;
		line-height: 60px;
		text-align: center;
		border-radius: 8px;
		font-weight: 900;
		font-family: "SimSun";
	}

	.navList {
		// width: 480px;
		// height: 20px;
		display: flex;
		// justify-content: space-between;
		align-items: center;
		// margin: 0 auto;

		.navItem {
			font-family: PingFang SC;
			font-size: 24px;
			font-weight: normal;
			height: 62px;
			line-height: 62px;
			border-radius: 4px;
			background: rgba(0, 119, 255, 0.09);
			color: #0077FF;
			backdrop-filter: blur(7px);
			margin-right: 24px;
			padding: 0 22px;
			display: flex;
			align-items: center;
			cursor: pointer;

			&:last-of-type {
				margin-right: 0;
			}

			&:hover {
				color: #ffffff;
				background-color: #0077FF;
			}

			//前面加图标
			&::before {
				content: "";
				display: inline-block;
				width: 24px;
				height: 24px;
				margin-right: 10px;
				background-size: 100%;
				background-repeat: no-repeat;
				// background-image: url(../assets/image/homeicon1.png);

			}

			&.active {
				// color: #616dff;
				color: #0077FF;
				// position: relative;
				// font-weight: bold;
				// width:  92px;
				// height: 62px;
				background-size: 100%;
				background-image: url(../assets/image/home-selice.png);
				background-repeat: no-repeat;


			}
		}

		.navItemicon1 {
			&::before {
				content: "";
				display: inline-block;
				width: 30px;
				height: 30px;
				margin-right: 10px;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-image: url(../assets/image/homeicon1.png);
			}

			&:hover::before {
				background-image: url(../assets/image/homeicon11.png); // 悬停时的图片路径
			}
		}

		.navItemicon2 {
			&::before {
				content: "";
				display: inline-block;
				width: 30px;
				height: 30px;
				margin-right: 10px;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-image: url(../assets/image/homeicon2.png);
			}

			&:hover::before {
				background-image: url(../assets/image/homeicon22.png); // 悬停时的图片路径
			}
		}

		.navItemicon3 {
			&::before {
				content: "";
				display: inline-block;
				width: 30px;
				height: 30px;
				margin-right: 10px;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-image: url(../assets/image/homeicon3.png);
			}

			&:hover::before {
				background-image: url(../assets/image/homeicon33.png); // 悬停时的图片路径
			}
		}
	}

	.searchTab {
		width: 993px;
		// height: 76px;
		// background: #fff;
		display: flex;
		align-items: center;
		// margin: 42px auto 0;
		margin-top: 48px;
		position: relative;
		// box-shadow: 0 10px 20px 0 rgba(41, 53, 92, 0.1);
		// border-radius: 38px;

		font-family: PingFang SC-Light, PingFang SC;

		.searchInput {

			// border-left: 1px solid #e8e8e8;
			outline: none;
			box-sizing: border-box;
			padding: 0 10px;
			font-size: 20px;
			text-indent: 12px;
			// border-radius: 38px 0 0 38px;
			background-color: transparent;
			width: 807px;
			height: 62px;
			border: 1px solid #0077FF;
		}

		.searchBtn {
			width: 167px;
			height: 76px;
			background: #616dff;
			box-shadow: 0px 0px 12px 0px rgba(41, 53, 92, 0.2);
			border-radius: 0px 38px 38px 0px;
			font-size: 20px;
		}

		.searchTipsBox {
			width: 807px;
			position: absolute;
			top: 66px;
			// left: 38px;
			background: #fff;
			box-shadow: 0px 0px 10px 1px rgba(0, 119, 255, 0.2);

			.searchTipsItem {
				width: 100%;
				height: 62px;
				display: flex;
				align-items: center;
				padding: 16px 5px 16px;
				box-sizing: border-box;
				cursor: pointer;
				font-size: 20px;
				font-family: PingFang SC-Light, PingFang SC;
				color: #262626;

				&:hover {
					background: #cce3ff;
					// opacity: 0.09;
				}

				.searchTipsTitle {
					height: 28px;
					background: rgba(67, 115, 232, 1);
					border-radius: 5px;
					box-sizing: border-box;
					padding: 8px 12px;
					font-size: 14px;
					@include flex-center;
					color: #fff;
				}

				.key {
					// font-size: 14px;
					// color: #666666;
					margin-left: 17px;
				}

				.item {
					// color: #333333;
					// font-size: 14px;
					margin-left: 10px;
					cursor: pointer;
				}
			}
		}
	}

	.card-box {
		display: flex;
		margin-top: 50px;
		width: 100%;

		.card {
			width: 295px;
			height: 183px;
			margin-right: 24px;
			background: linear-gradient(181deg, #CBE3FF 0%, #FFFFFF 100%);
			opacity: 1;
			border: 2px solid #FFFFFF;
			font-size: 26px;
			font-family: PingFang SC-Light, PingFang SC;
			// font-weight: 300;
			color: #262626;
			// line-height: 0px;
			// display: flex;
			// align-items: center;
			// flex-direction: column;
			cursor: pointer;

			&:hover {
				box-shadow: 2px 10px 20px 1px rgba(0, 119, 255, 0.3);

			}

			img {
				margin: 0 20px 0 32px;
			}

			.top-title {
				display: flex;
				align-items: center;
				margin-top: 34px;
				margin-bottom: 22px;

			}
.card-text{
font-family: PingFang SC;
font-size: 13px;
font-weight: normal;
line-height: 20px;
text-align: justify; /* 浏览器可能不支持 */
letter-spacing: 0em;
padding: 0 24px;
color: #737373;
}
			// flex-direction: column;
		}
	}

	.notice {
		display: flex;
		font-size: 24px;
		font-family: PingFang SC-Light, PingFang SC;
		// font-weight: 300;
		margin-top: 40px;
		color: #FF5858;
		align-items: center;

		img {

			margin-right: 14px;
		}
	}

	.content {
		display: flex;
		width: 100%;
		margin-top: 54px;

		.leftContent,
		.rightContent {
			flex: 4;
			padding: 0 27px 20px;
			box-sizing: border-box;

			.topNav {
				width: 100%;
				display: flex;
				box-shadow: 0px 10px 20px 0px rgba(41, 53, 92, 0.1);
				border-radius: 6px;
				height: 186px;

				.navitem {
					flex: 1;
					height: 100%;
					cursor: pointer;

					.disabled {
						filter: grayscale(100%);
					}

					img {
						display: block;
						width: 64px;
						height: 64px;
						margin: 41px auto 23px;
					}

					.itemName {
						text-align: center;
					}
				}
			}

			.bottomContent {
				height: 430px;
				width: 100%;
				overflow: auto;
				margin-top: 30px;
				box-shadow: 0px 10px 20px 0px rgba(41, 53, 92, 0.1);
				border-radius: 6px;
				box-sizing: border-box;
				padding: 25px;

				.bottomTitle {
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 18px;
					color: #333333;
					font-weight: 500;
					font-family: Source Han Sans CN;
				}

				.bottomTabList {
					color: #999999;
					font-size: 16px;
					font-weight: 500;
					display: flex;
					margin-top: 40px;

					.bottomTabItem {
						position: relative;
						margin-right: 60px;
						transition: 0.3s;
						cursor: pointer;

						&:hover,
						&.active {
							color: #6b5af4;

							&::after {
								content: "";
								width: 36px;
								height: 2px;
								background: #6b5af4;
								position: absolute;
								bottom: -15px;
								left: calc(50% - 18px);
							}
						}
					}
				}

				.itemContent {
					width: 100%;
					height: calc(100% - 30px);
					overflow: auto;
				}

				.item {
					width: 100%;
					height: 82px;
					border-bottom: 1px solid #d6d7d9;
					position: relative;
					cursor: pointer;

					.info {
						font-size: 16px;
						color: #333333;
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						position: absolute;
						top: 13px;
						left: 0;
					}

					.time {
						color: #999999;
						position: absolute;
						top: 51px;
						left: 0;
					}

					.num {
						color: #999999;
						position: absolute;
						top: 51px;
						left: 117px;
					}

					.from {
						padding: 0 10px;
						width: auto;
						line-height: 24px;
						height: 24px;
						background: rgba(239, 245, 255, 1);
						border: 1px solid rgba(21, 98, 214, 1);
						border-radius: 2px;
						position: absolute;
						color: #1562d6;
						top: 51px;
						left: 256px;
					}
				}
			}
		}

		.leftContent {
			flex: 5;
			padding-right: 3px;
		}
	}
}
</style>
