import axios from 'axios'; // 引入axios
import store from '@/store/index.js';
import router from '@/router/index.js';
import _this from '../main.js'
import qs from "qs";

import {
    Message
} from 'view-design';
import toase from './toase'
if (process.env.NODE_ENV == ' developmentlocal') {//本地开发
    // axios.defaults.baseURL = 'http://192.168.3.152:8007'; //嘉坤
    // axios.defaults.baseURL = 'http://192.168.3.163:8007';//开发
    axios.defaults.baseURL = 'http://192.168.3.228:8007';//开发-久剑
    // axios.defaults.baseURL = 'http://192.168.3.194:8007';
    // axios.defaults.baseURL = 'http://192.168.3.152:8205';
    // axios.defaults.baseURL = 'https://dev.api.zstnb.cn:18081';//开发库
    // axios.defaults.baseURL = 'https://api.zstnb.cn:18081';//测试库
    // axios.defaults.baseURL = 'http://192.168.3.54:8007';
} else if (process.env.NODE_ENV == 'development') {//线上开发
    // axios.defaults.baseURL = 'http://192.168.3.163:8007';//开发
    axios.defaults.baseURL = 'https://api.zstnb.cn:18081';


    //  axios.defaults.baseURL = 'https://api.zhongsituo.com:10443';
    //  axios.defaults.baseURL = 'https://api.zstnb.cn:18081';//测试库


} else if (process.env.NODE_ENV == 'test') {//测试
    axios.defaults.baseURL = 'https://api.zstnb.cn:18081';
} else if (process.env.NODE_ENV == 'pre') {//预生产
    axios.defaults.baseURL = 'https://api.zhongsituo.com:10443';
} else if (process.env.NODE_ENV == 'production') {//生产
    axios.defaults.baseURL = 'https://api.zhongsituo.com';
    // axios.defaults.baseURL = 'http://192.168.3.131:8007';
}
let baseURL = axios.defaults.baseURL;
export function getBaseURL() {
    return baseURL;
}
axios.defaults.timeout = 60000*2;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

      const pendingRequest = new Map();
function generateReqKey(config) {
    const { method, url, params, data } = config;
    return [method, url, qs.stringify(params), qs.stringify(data)].join(
        "&"
    );
}

function addPendingRequest(config) {
    const requestKey = generateReqKey(config);
    config.cancelToken =
        config.cancelToken ||
        new axios.CancelToken((cancel) => {
            if (!pendingRequest.has(requestKey)) {
                pendingRequest.set(requestKey, cancel);
            }
        });
}

function removePendingRequest(config) {
    const requestKey = generateReqKey(config);
    if (pendingRequest.has(requestKey)) {
        const cancel = pendingRequest.get(requestKey);
        cancel(requestKey);
        pendingRequest.delete(requestKey);
    }
}

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断vuex中是否存在token
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = store.state.token;
        token && (config.headers.Authorization = token);

        if (config.data instanceof FormData) {
            config.headers['Content-Type'] = 'multipart/form-data';
        }
  removePendingRequest(config); // 检查是否存在重复请求，若存在则取消已发的请求
    addPendingRequest(config); // 把当前请求添加到pendingRequest对象中
        return config;
    },
    error => {
        return error;
    }
);

//接口白名单
const whiteList = ['/service-analyze/EvlBatchExcelUpload/downloadErrorMsg', '/service-evaluation/BatchTaskEvaluation/taskDetailExport', '/service-evaluation/batchEvlHisInfo/downLoadExcel']

// 响应拦截器

axios.interceptors.response.use(
    response => {
   removePendingRequest(response.config); // 从pendingRequest对象中移除请求

        // 如果返回的状态码为0，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (whiteList.indexOf(response.config.url) > -1) {
            return Promise.resolve(response);
        }
        // if (response.config.url.indexOf('downloadErrorMsg') > -1) {
        //     return Promise.resolve(response);
        // }
        if (response.data instanceof Blob) {
            return Promise.resolve(response);
        }
        if (response.status === 200) {
            if (response.data.code === 0) {
                return Promise.resolve(response.data);
            } else if (response.data.code === -1) {
                _this.$msg.error({
                    text: response.data.data,
                })
            } else {
                _this.$msg.error({
                    text: _this.$errorCode[response.data.code],
                })
                return Promise.reject(response)
            }


        } else {
            if (response.data.code === -1) {
                _this.$msg.error({
                    text: response.data.data,
                });
            }

            return Promise.reject(response);
        }
    },
    error => {
   removePendingRequest(error.config || {}); // 从pendingRequest对象中移除请求

        switch (error.response.status) {
            case 401:
                // router.history.current.path == '/login' && Message.error('密码错误');
                // 清除token

                localStorage.removeItem('token');
                store.commit('changeState', {
                    prop: 'token',
                    value: ''
                });
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面

                setTimeout(() => {
                    //判断当前路由
                    if (_this.$route.path=='/login') {
                        return
                    }
                    router.replace({
                        path: '/login',
                        query: {
                            redirect: router.currentRoute.fullPath
                            // redirect: '/Research/ResearchReport'
                        }
                    });
                }, 1000);
                // window.location.reload();
                break;
            // 404请求不存在
            case 404:

                // Message.error('网络请求不存在');
                // Message.error(error.response.data.message || '网络请求不存在');
                break;
            // 其他错误，直接抛出错误提示
            default:
            // Message.error(error.response.data.message || '网络繁忙，请稍后再试');

        }
        return Promise.reject(error.response);
    }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get({
    url,
    params
}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            });
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post({
    url,
    params
}) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            });
    });
}


/**
 * post方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function _delete({
    url,
    params
}) {
    return new Promise((resolve, reject) => {
        axios
            .delete(url, {
                params: params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            });
    });
}